import { ARCanvas, NFTMarker } from "react-three-arnft";

import React from "react";

const marker = {
  width: 100,
  height: 100,
};

function App() {
  return (
    <div className="App">
      <ARCanvas
        interpolationFactor={24}
        dpr={window.devicePixelRatio}
        onCreated={({ gl }) => {
          gl.setSize(window.innerWidth, window.innerHeight);
        }}
      >
        <NFTMarker url={"data/qr_4"}>
          <group position={[marker.width * 0.5, marker.height * 0.5, 0]}>
            <mesh scale={[marker.width, marker.height, 10]}>
              <boxBufferGeometry args={[1, 1, 1]} />
              <meshNormalMaterial opacity={0.5} transparent={true} />
            </mesh>
          </group>
        </NFTMarker>
        <ambientLight />
      </ARCanvas>
    </div>
  );
}

export default App;
